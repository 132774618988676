<template>
	<div>
		<div class="bg-white border-bottom">
			<div class="header mb-0">
				<div class="container-fluid">
					<div class="header-body py-0 mt-4 border-0">
						<div class="row align-items-end">
							<div class="col">
								<h6 class="header-pretitle">VEHICLE PARTNERS OVERVIEW</h6>
								<h1 class="header-title">{{ partnerDets?.owner.fname }} {{ partnerDets?.owner.lname }}</h1>
							</div>
							<div>
								<button type="button"
									v-if="userHasPermission('partners014')"
									id="reviewPayoutButton"
									aria-expanded="false"
									@click="showPayout()"
									class="btn btn-lg btn-black text-white">Approve</button>
							</div>
						</div>
						<!-- / .row -->
					</div>
					<!-- / .header-body -->
				</div>
			</div>
			<div class="container-fluid">
				<div class="row pt-3 pb-1">
					<div class="col justify-self-center align-self-center flex-column">
						<ul class="nav nav-pills">
							<li class="nav-item">
								<router-link
									:to="{
										name: 'PartnerInformation',
									}"
									class="nav-link"
									active-class="active"
									>Partner Information</router-link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<template>
			<review-payout-modal
				:show="showPayoutModal"
				:partner_sid="partnerDets?.account_sid"
				:earnings_id="earnings_id"
				@close="showPayoutModal = false"
				@showRefund="showPayout()"
				@updated="updatePage()"
				>
			</review-payout-modal>
		</template>
		<div>
			<div class="container-fluid">
				<router-view :key="componentKey"></router-view>
			</div>
		</div>

	</div>
</template>


<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
import ReviewPayoutModal from '@/components/modals/ReviewPayoutModal.vue'
import Swal from 'sweetalert2'

export default {
  name: 'ManagePayout',
  components: {
    ReviewPayoutModal
  },
  data () {
    return {
      showPayoutModal: false,
      id: parseInt(this.$route.params.payoutId),
      earnings_id: this.$route.params.earningId,
      partnerDets: null,
      loading: false,
      earnings: null,
      componentKey: 0
    }
  },
  methods: {
    showPayout () {
      if (this.earnings.settlementAccount == null) {
        this.$swal({
          icon: 'error',
          title: 'You cannot approve a Partner without a Settlement Account',
          showConfirmButton: true,
          showCancelButton: false
        })
      } else {
        this.showPayoutModal = !this.showPayoutModal
      }
    },
    async getPartnerDetails () {
      this.loading = true
      try {
        const res = await this.axios.get(`/v1/partners/${this.id}`)
        if (res.status == 200) {
          const r = res.data
          r.owner.fname = r.owner.fname.charAt(0).toUpperCase() + r.owner.fname.slice(1)
          r.owner.lname = r.owner.lname.charAt(0).toUpperCase() + r.owner.lname.slice(1)
          this.partnerDets = r
        }
      } catch (err) {
        
      } finally {
        this.loading = false
        this.getPartnerEarningsDets()
      }
    },
    async getPartnerEarningsDets () {
      try {
        const res = await this.axios.get(`cost-revenue/v1/earnings/${this.earnings_id}`)
        if (res.status == 200) {
          this.earnings = res.data
        }
      } catch (err) {
        
      }
    },
    updatePage () {
      this.componentKey += 1
    }
  },
  created () {
    this.getPartnerDetails()
    this.updatePage()
  }
}
</script>

<style lang="scss" scoped>
.nav.nav-pills a.nav-link{
  color: #fff !important;
}
.btn-black{
   background-color:#000005;
}
</style>
